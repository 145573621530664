<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="How to use the SQL Broadcaster" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <mex-p fontSize="h5" content="SQL-Broadcaster Workflow" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pl-16">
        <mex-p
          content="With  every request by the GET_CLINIC_UPDATES endpoint of a clinic the License Server checks if there are SQL Broadcaster statements assigned to this specific clinic.
         If so the License Server adds this statements to the response which is send back to the requesting clinic."
        />
        <mex-p
          content="The final procedure of the GET_CLINIC_UPDATES endpoint then evaluates the received statements.
         If the statements follow the required syntax and do not contain forbidden keywords the statements will be processed. This starts by adding the SEND_SQL_DATA endpoint to the REST API queue and with the statement as part of the parameters. If the statement is executable the result will be send to the MedITEX License Server as content of the SEND_SQL_DATA request. Every statement will only be executed once per assignment.
         This means if a statement was successfully executed with the SEND_SQL_DATA the provided StatementID besides some other information is saved in the clinics database which will be checked during the evaluation taking place in the GET_CLINIC_UPDATES final procedure.
         This StatementID is unique for each “statement to clinic”-assignment."
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <mex-p fontSize="h5" content="Statement Syntax" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pl-16">
        <mex-p content="The statements have to follow a specific syntax to be executed on the clinic side." />
        <mex-p content="Every statement needs to start with the SELECT keyword. Its not allowed to use sub-selects of any kind. The fields to be selected must be specified as parameters of the ANO() function as in the following example." />
        <mex-p content="You can use the parameter DISTINCT, and aggregate functions such as: SUM(), MAX(), MIN(), COUNT()." />
      </v-col>
    </v-row>
    <v-row v-for="example in examples" :key="example">
      <v-col cols="12">
        <code-editor :key="example" :code=example.content />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pl-16">
        <mex-p content="The following keywords are forbidden and if contained in any statement cause it to be rejected:" />
        <ul
          class="pl-16"
          v-for="(item, idx) in sqlBroadcasterConfig.forbiddenKeywords"
          :key="item + idx"
        >
          <li>{{ item }}</li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import CodeEditor from "../../components/LicSrvComponents/CodeEditor.vue";
import SqlStatementsService from "../../services/sqlStatements.service";

export default {
  name: "SqlStatementHelp.vue",
  components: { CodeEditor },
  data() {
    return {
      examples: [
        {
          content: "" +
            "SELECT\n" +
            "--   +------------------------------------------ ANO() FUNCTION\n" +
            "--   |        +--------------------------------- TABLE NAME\n" +
            "--   |        |               +----------------- FIELD NAME\n" +
            "--   V        V               V                        \n" +
            "\tANO(ANAMNESE_ITEM.anamnese_item_id),\n" +
            "\tANO(ANAMNESE_ITEM.typ_id),\n" +
            "\tANO(ANAMNESE_ITEM.datum)\n" +
            "FROM\n" +
            "\tANAMNESE_ITEM\n" +
            "WHERE\n" +
            "\tsub_typ_id is null"
        },
        {
          content: "" +
            "--         +------------------------------------ DISTINCT OPERATOR\n" +
            "--         v\n" +
            "SELECT DISTINCT\n" +
            "--   +------------------------------------------ ANO() FUNCTION\n" +
            "--   |        +--------------------------------- TABLE NAME\n" +
            "--   |        |        +------------------------ FIELD NAME\n" +
            "--   V        V        V                                   \n" +
            "\tANO(ANAMNESE_ITEM.typ_id),\n" +
            "\tANO(ANAMNESE_ITEM.datum)\n" +
            "FROM\n" +
            "\tANAMNESE_ITEM"
        },
        {
          content: "" +
            "SELECT\n" +
            "--  +---------------------------------------------- COUNT() FUNCTION\n" +
            "--  |        +------------------------------------- DISTINCT OPERATOR\n" +
            "--  |        |      +------------------------------ ANO() FUNCTION\n" +
            "--  |        |      |    +------------------------- TABLE NAME\n" +
            "--  |        |      |    |         +--------------- FIELD NAME\n" +
            "--  v        v      V    V         V                      \n" +
            "\tCOUNT(DISTINCT ANO(PERSON.GEBURTSDATUM))\n" +
            "FROM\n" +
            "\tPERSON"
        }
      ],
      sqlBroadcasterConfig: null
    };
  },
  methods: {
    fetchSqlBroadcasterConfig() {
      SqlStatementsService.getSqlBroadcasterConfig()
        .then(response => {
          this.sqlBroadcasterConfig = response.data;
        })
        .catch(err => {
          this.$toast.error(err.response.data);
        });
    }
  },
  created() {
    this.fetchSqlBroadcasterConfig();
  }

};
</script>

<style scoped>

</style>
